import {
    ACESFilmicToneMapping,
    Box3,
    DoubleSide,
    EquirectangularReflectionMapping,
    LoadingManager,
    Mesh,
    MeshPhysicalMaterial,
    MeshStandardMaterial,
    OrthographicCamera,
    PerspectiveCamera,
    PlaneGeometry,
    Scene,
    Sphere,
    WebGLRenderer,
} from 'three';

import {MeshoptDecoder} from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader.js';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import {generateRadialFloorTexture} from './utils/generateRadialFloorTexture.js';
import {GradientEquirectTexture, WebGLPathTracer} from 'three-gpu-pathtracer';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {ParallelMeshBVHWorker} from 'three-mesh-bvh/src/workers/ParallelMeshBVHWorker.js';
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";

const params = {

    model: '',

    gradientTop: '#bfd8ff',
    gradientBottom: '#ffffff',

    environmentIntensity: 1.0,
    environmentRotation: 0,

    enable: false,
    pause: false,

    floorOpacity: 1.0,
    floorRoughness: 0.2,
    floorMetalness: 0.2,


};

let floorPlane, gui, stats;
let pathTracer, renderer, orthoCamera, perspectiveCamera, activeCamera;
let controls, scene, model, dracoPath;
let gradientMap;
let oldSampleCount = 0;

const orthoWidth = 2;

init();

async function init() {


    // renderer
    renderer = new WebGLRenderer({antialias: true, preserveDrawingBuffer: true});
    renderer.toneMapping = ACESFilmicToneMapping;
    document.body.appendChild(renderer.domElement);

    // path tracer
    pathTracer = new WebGLPathTracer(renderer);
    pathTracer.setBVHWorker(new ParallelMeshBVHWorker());
    pathTracer.physicallyCorrectLights = true;

    // if you have a bad graphics card
    pathTracer.tiles.set( 7, 7 );
    // pathTracer.tiles.set(3, 3);

    pathTracer.multipleImportanceSampling = true;
    pathTracer.transmissiveBounces = 10;
    pathTracer.bounces = 5;
    pathTracer.filterGlossyFactor = 0.5;
    pathTracer.renderScale = 1;

    // camera
    const aspect = window.innerWidth / window.innerHeight;
    perspectiveCamera = new PerspectiveCamera(60, aspect, 0.025, 500);
    perspectiveCamera.position.set(-1, 0.25, 1);

    const orthoHeight = orthoWidth / aspect;
    orthoCamera = new OrthographicCamera(orthoWidth / -2, orthoWidth / 2, orthoHeight / 2, orthoHeight / -2, 0, 100);
    orthoCamera.position.set(-1, 0.25, 1);

    // background map
    gradientMap = new GradientEquirectTexture();
    gradientMap.topColor.set('#ffffff');
    gradientMap.bottomColor.set('#ffffff');
    gradientMap.update();

    // controls
    controls = new OrbitControls(perspectiveCamera, renderer.domElement);
    controls.addEventListener('change', () => {
        pathTracer.updateCamera();
    });

    // scene
    scene = new Scene();
    scene.background = gradientMap;

    const floorTex = generateRadialFloorTexture(2048);
    floorPlane = new Mesh(
        new PlaneGeometry(),
        new MeshStandardMaterial({
            map: floorTex,
            transparent: true,
            color: 0xFFFFFF,
            roughness: 0.1,
            metalness: 0.0,
            side: DoubleSide,
        })
    );

    floorPlane.scale.setScalar(5);
    floorPlane.rotation.x = -Math.PI / 2;
    scene.add(floorPlane);

    stats = new Stats();
    document.body.appendChild(stats.dom);

    updateCameraProjection('Perspective');
    onResize();

    compileInfo();
    animate();

    window.addEventListener('resize', onResize);

    triggerMessaging();

}

function triggerMessaging() {
    window.parent.postMessage({
        type: 'WAITING_MODEL',
        data: {},
    }, '*');

    window.onmessage = async (event) => {
        if (event.data) {
            switch (event.data.type) {
                case 'LOAD_MODEL':
                    console.log('LOAD_MODEL received', event.data);
					dracoPath = event.data.value.dracoPath;
                    updateGradient(event.data.value.colorScene);
                    updateEnvMap(event.data.value.environment);
                    updateModel(event.data.value);
                    break;
                case 'LOAD_ENVIRONMENT':
                    console.log('LOAD_ENVIRONMENT received', event.data);
                    updateEnvMap(event.data.value.environment);
                    break;

                case 'LOAD_COLOR_SCENE':
                    console.log('LOAD_COLOR_SCENE received', event.data);
                    updateGradient(event.data.value.colorScene);
                    break;
				case 'START_RENDER':
					console.log('START_RENDER received');
					params.pause = false;
					params.enable = true;
					window.parent.postMessage({
						type: 'STATUS_RUNNING',
						data: {},
					}, '*');
					break;
				case 'PAUSE_RENDER':
					console.log('PAUSE_RENDER received');
					params.pause = true;
					window.parent.postMessage({
						type: 'STATUS_PAUSED',
						data: {},
					}, '*');
					break;
				case 'EXPORT_RENDER':
					console.log('EXPORT_RENDER received');
					// window.parent.postMessage({
					// 	type: 'EXPORT_RENDER',
					// 	data: {},
					// }, '*');

					const screenshotDataUrl = renderer.domElement.toDataURL();
					const link = document.createElement('a');
					link.href = screenshotDataUrl;
					const now = new Date();

					const day = String(now.getDate()).padStart(2, '0');
					const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
					const year = now.getFullYear();
					const hours = String(now.getHours()).padStart(2, '0');
					const minutes = String(now.getMinutes()).padStart(2, '0');

					const timestampString = `${day}${month}${year}_${hours}${minutes}`;
					link.download = timestampString + '_render.png';
					link.click();

					break;
            }
        }
    };
}

function animate() {

    requestAnimationFrame(animate);

    stats.update();

    if (!model) {
        return;
    }

    if (params.enable) {

        if (!params.pause || pathTracer.samples < 1) {

            pathTracer.renderSample();

        }

    } else {

        renderer.render(scene, activeCamera);

    }

	sampleSend();
}

async function sampleSend() {
	if(Math.abs(pathTracer.samples - oldSampleCount) > 3){
		oldSampleCount = pathTracer.samples;
		window.parent.postMessage({
			type: 'RENDER_SAMPLES',
			value: {
				sampleCount: Math.floor(pathTracer.samples),
			},
		}, '*');
	}
}

function compileInfo() {
    let lastState;

    setInterval(() => {
        if(lastState !== pathTracer.isCompiling){
            lastState = pathTracer.isCompiling;
            window.parent.postMessage({
                type: 'COMPILE_STATE',
                value: {
                    compiling: lastState,
                },
            }, '*');
        }
    }, 100);
}

function onParamsChange() {

    scene.environmentIntensity = params.environmentIntensity;
    scene.environmentRotation.y = params.environmentRotation;

    if (params.transparentBackground) {

        scene.background = null;
        renderer.setClearAlpha(0);

    }

    pathTracer.updateMaterials();
    pathTracer.updateEnvironment();

}

function onResize() {

    const w = window.innerWidth;
    const h = window.innerHeight;
    const dpr = window.devicePixelRatio;

    renderer.setSize(w, h);
    renderer.setPixelRatio(dpr);

    const aspect = w / h;
    perspectiveCamera.aspect = aspect;
    perspectiveCamera.updateProjectionMatrix();

    const orthoHeight = orthoWidth / aspect;
    orthoCamera.top = orthoHeight / 2;
    orthoCamera.bottom = orthoHeight / -2;
    orthoCamera.updateProjectionMatrix();

    pathTracer.updateCamera();
}

function buildGui() {
    if (gui) {

        gui.destroy();

    }
}

function updateEnvMap(path) {

    new RGBELoader()
        .load(path, texture => {

            if (scene.environment) {

                scene.environment.dispose();

            }

            texture.mapping = EquirectangularReflectionMapping;
            scene.environment = texture;
            pathTracer.updateEnvironment();

        });

}

function updateGradient(scenery) {

    if(scenery.transparent){
        scene.background = null;
        renderer.setClearAlpha(0);
        document.body.classList.add( 'checkerboard' );

        floorPlane.scale.setScalar(0.0001);
        floorPlane.material.opacity = 0;
    }else {
        gradientMap.topColor.set(scenery.top);
        gradientMap.bottomColor.set(scenery.bottom);
        gradientMap.update();
        scene.background = gradientMap;
        scene.backgroundIntensity = 1;
        scene.environmentRotation.y = 0;

        floorPlane.material.color.set( hexStringToHex(scenery.floor) );
        document.body.classList.remove( 'checkerboard' );

        floorPlane.scale.setScalar(5);
        floorPlane.material.opacity = 1;
    }

    pathTracer.updateMaterials();
    pathTracer.updateEnvironment();
}

function updateCameraProjection(cameraProjection) {

    // sync position
    if (activeCamera) {

        perspectiveCamera.position.copy(activeCamera.position);
        orthoCamera.position.copy(activeCamera.position);

    }

    // set active camera
    if (cameraProjection === 'Perspective') {

        activeCamera = perspectiveCamera;

    } else {

        activeCamera = orthoCamera;

    }

    controls.object = activeCamera;
    controls.update();

    pathTracer.setCamera(activeCamera);

}

function convertOpacityToTransmission(model, ior) {

    model.traverse(c => {

        if (c.material) {

            const material = c.material;
            if (material.opacity < 0.65 && material.opacity > 0.2) {

                const newMaterial = new MeshPhysicalMaterial();
                for (const key in material) {

                    if (key in material) {

                        if (material[key] === null) {

                            continue;

                        }

                        if (material[key].isTexture) {

                            newMaterial[key] = material[key];

                        } else if (material[key].copy && material[key].constructor === newMaterial[key].constructor) {

                            newMaterial[key].copy(material[key]);

                        } else if ((typeof material[key]) === 'number') {

                            newMaterial[key] = material[key];

                        }

                    }

                }

                newMaterial.opacity = 1.0;
                newMaterial.transmission = 1.0;
                newMaterial.ior = ior;

                const hsl = {};
                newMaterial.color.getHSL(hsl);
                hsl.l = Math.max(hsl.l, 0.35);
                newMaterial.color.setHSL(hsl.h, hsl.s, hsl.l);

                c.material = newMaterial;

            }

        }

    });

}

async function updateModel(newModel) {

    if (gui) {

        document.body.classList.remove('checkerboard');
        gui.destroy();
        gui = null;

    }

    renderer.domElement.style.visibility = 'hidden';

    if (model) {

        model.traverse(c => {

            if (c.material) {

                const material = c.material;
                for (const key in material) {

                    if (material[key] && material[key].isTexture) {

                        material[key].dispose();

                    }

                }

            }

        });

        scene.remove(model);
        model = null;

    }

    try {

        model = await loadModel(newModel.glb, v => {
        });

    } catch (err) {

        console.error('Failed to load model:' + err.message);
    }

    // update after model load
    // TODO: clean up
    if (newModel.removeEmission) {

        model.traverse(c => {

            if (c.material) {

                c.material.emissiveMap = null;
                c.material.emissiveIntensity = 0;

            }

        });

    }

    if (newModel.opacityToTransmission) {

        convertOpacityToTransmission(model, modelInfo.ior || 1.5);

    }

    if (model) {
        model.traverse(c => {

            if (c.material) {

                // set the thickness so we render the material as a volumetric object
                c.material.thickness = 1.0;

            }

        });
    }


    if (newModel.postProcess) {

        newModel.postProcess(model);

    }

    // rotate model after so it doesn't affect the bounding sphere scale
    if (newModel.rotation) {

        model.rotation.set(...newModel.rotation);

    }

    // center the model
    const box = new Box3();
    box.setFromObject(model);
    model.position
        .addScaledVector(box.min, -0.5)
        .addScaledVector(box.max, -0.5);

    const sphere = new Sphere();
    box.getBoundingSphere(sphere);

    model.scale.setScalar(1 / sphere.radius);
    model.position.multiplyScalar(1 / sphere.radius);
    box.setFromObject(model);
    floorPlane.position.y = box.min.y;

    scene.add(model);

    await pathTracer.setSceneAsync(scene, activeCamera, {
    });

    window.parent.postMessage({
        type: 'STATUS_READY',
        data: {},
    }, '*');

    params.bgGradientTop = newModel.gradientTop || '#ffffff';
    params.bgGradientBottom = newModel.gradientBot || '#ffffff';

    buildGui();
    onParamsChange();

    renderer.domElement.style.visibility = 'visible';
    if (params.checkerboardTransparency) {
        document.body.classList.add('checkerboard');
    }

}

function hexStringToHex(colorStr) {
    // Remove the '#' if present
    let cleanColor = colorStr.startsWith('#') ? colorStr.slice(1) : colorStr;

    // Convert the string to an integer
    return parseInt(cleanColor, 16);
}


async function loadModel(url, onProgress) {
    return new Promise(async (resolve, reject) => {
        const blobURL = URL.createObjectURL(url)

        const manager = new LoadingManager();
        const complete = new Promise(resolve => manager.onLoad = resolve);


		const gltfLoader = new GLTFLoader(manager);
		const dracoLoader = new DRACOLoader();

		// The dracoPath variable is used to determine the path for the Draco decoder.
		// If globalThis.dracoPath is defined, it means the viewer is being used in the Mazing world context.
		// Otherwise, it defaults to the local Draco directory, implying the viewer is being used standalone.
		dracoLoader.setDecoderPath(dracoPath);
		dracoLoader.setDecoderConfig({type: 'js'});
		gltfLoader.setDRACOLoader(dracoLoader);
		gltfLoader.setMeshoptDecoder(MeshoptDecoder);

        const gltf = await gltfLoader.loadAsync(blobURL, progress => {
            if (progress.total !== 0 && progress.total >= progress.loaded) {
                onProgress(progress.loaded / progress.total);
            }
        });
        await complete;
        resolve(gltf.scene);
    });
}
